// import Slick Slider JS
import "slick-carousel";
import jQuery from "jquery";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import(/* webpackPrefetch: true */ "../../utils/numberCount.js");
import { Modal } from "bootstrap";

jQuery(document).ready(function ($) {
  //start the slider
  if ($(".home_slider").length > 0) {
    $(".home_slider").slick({
      lazyLoad: "progressive",
      cssEase: "cubic-bezier(0.87, 0.03, 0.41, 0.9)",
      autoplay: true,
      autoplaySpeed: 3000,
      dots: false,
      arrows: false,
    });
  }

  if ($(".testimonial_slider").length > 0) {
    $(".testimonial_slider").slick({
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 3000,
    });
  }

  //Select2
  $("#region-select").on("change", function () {
    var allContents = $(".area-content");
    allContents.each(function () {
      $(this).removeClass("area-content_active").addClass("area-content_hide");
    });

    var selectedValue = $(this).val();
    var selectedContent = $("#" + selectedValue);
    if (selectedContent.length) {
      selectedContent
        .removeClass("area-content_hide")
        .addClass("area-content_active");
    }
  });

  $("#region-select").trigger("change");

  //-------------------------------------
  $(".scroll-trigger a").on("click", function (e) {
    document.querySelector("body").classList.remove("menu-open");
    // Prevent the default anchor click behavior
    e.preventDefault();

    // Get the data-target attribute to identify the target element to scroll to
    var targetID = $(this).attr("href");
    var targetElement = $(targetID);

    // Use the animate function to scroll to the target element
    $("html, body").animate(
      {
        scrollTop: targetElement.offset().top - 72,
      },
      500
    ); // Adjust the duration (1000 ms here) as per your requirement
  });
  //---------------------------------------------
  if ($(".why_to_choose_lists").length > 0 && $(window).width() < 992) {
    $(".why_to_choose_lists ul").slick({
      autoplaySpeed: 3000,
      dots: false,
      arrows: false,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 420,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });
  }
});
